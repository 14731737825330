import { createGlobalStyle } from 'styled-components';
import { LoaderScreenStyles } from './components/Navigation';
import { AppMenuStyles } from './components/AppMenu';
import { ContentLoggedWrapperStyles } from './templates/ContentLoggedWrapper';
import { CustomLinkStyles } from './components/CustomLink';
import { BubbleHeaderStyles } from './components/BubbleHeader';
import { HelpCardStyles } from './components/HelpCard/HelpCard';
import { AppFooterStyles } from './templates/AppFooter';
import { CookiesBannerStyles } from './components/Cookies/CookiesBanner';
import { CommunityChangeStyles } from './components/CommunityChange';
import { PaginationStyles } from './components/CommunityChange/CommunitiesList/Pagination';
import { SwitcherStyles } from './components/Switcher';

const AppStyles = createGlobalStyle`
.sds-reset-style{
    ${LoaderScreenStyles}
    ${ContentLoggedWrapperStyles}
    ${CustomLinkStyles}
    ${AppMenuStyles}
    ${HelpCardStyles}
    ${BubbleHeaderStyles}
    ${AppFooterStyles}
    ${CookiesBannerStyles}
    ${CommunityChangeStyles}
    ${PaginationStyles}
    ${SwitcherStyles}
}
`;

export default AppStyles;
