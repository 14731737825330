import { FC } from 'react';
import { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
//SDS
import Container from 'saur-design-sys-web/lib/Atoms/Container/Container';
import Word from 'saur-design-sys-web/lib/Atoms/Word/Word';
import {
    device,
    onlyForDesktop,
    onlyForMobile
} from 'saur-design-sys-web/lib/Theme';
import Button from 'saur-design-sys-web/lib/Atoms/Input/Button/Button';

//CPOSM
//Internal
import { DesktopMenuState } from '../../common/customTypes';
import HelpCard from '../HelpCard/HelpCard';

type BottomMenuContentProps = {
    desktopMenuState?: DesktopMenuState;
};

const BottomMenuContent: FC<BottomMenuContentProps> = ({
    desktopMenuState
}) => {
    const { t } = useTranslation();
    
    return (
        <Container className='bottom-content'>
            <Container className='help-container-mobile'>
                <Button mode='basic'>
                    <Container className='help-button-mobile'>
                        <img
                            src='/assets/help-icon.svg'
                            alt='Help icon' />
                        <Word
                            value={t('menu.help.title')}
                            textStyle='normalBold'
                            className='card-title' />
                    </Container>
                </Button>
            </Container>

            <HelpCard desktopMenuState={desktopMenuState} />
        </Container>
    );
};

export const BottomMenuContentStyles = css`
    .bottom-content {
        padding-top: 30px;

        @media ${device.desktop} {
            padding-left: 28px;
            padding-right: 28px;
            padding-bottom: 12px;
            padding-top: 30px;
        }

        .help-card {
            ${onlyForDesktop}
            background-color:#102B4E;
        }

        .help-container-mobile {
            ${onlyForMobile}
            background-color:#102B4E;
            padding: 20px 14px;
            justify-content: center;
        }
        .help-button-mobile {
            flex-direction: row;
        }

        .card-header {
            flex-direction: row;
            align-items: center;
        }

        .card-title {
            color: #ffffff;
            margin-left: 10px;
        }

        .help-button-text {
            color: #ffffff;
        }

        .card-desc {
            color: #ffffff;
            margin-top: 16px;
            margin-bottom: 16px;
        }
    }
`;

export default BottomMenuContent;